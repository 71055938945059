import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import isBrowser from '../../helper/is-browser';
import footerStyles from './footer.module.scss';

const scrollToTop = () => {
  if (isBrowser) {
    // this is required because of gatsby does not have 'window' reference while compiling
    const SmoothScroll = isBrowser ? require('smooth-scroll') : 'undefined'; // eslint-disable-line global-require
    const scroll = new SmoothScroll();
    scroll.animateScroll(0);
  }
};

const Footer = ({ node }) => (
  <footer className={footerStyles.footer}>
    <Link to="/">
      <img
        className={footerStyles.logo}
        key={node.logo.id}
        src={node.logo.file.url}
        alt={node.logo.file.fileName}
      />
    </Link>
    <ul className={footerStyles.linkListWrapper}>
      {node.links.map(link => (
        <li className={footerStyles.linkListItem} key={link.id}>
          <a className={footerStyles.link} href={link.externalUrl}>
            {link.displayName}
          </a>
        </li>
      ))}
    </ul>
    <button className={footerStyles.button} onClick={() => scrollToTop()}>
      <span className={footerStyles.backtotop}>Back to top</span>
    </button>
  </footer>
);

Footer.propTypes = {
  node: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

export default Footer;
