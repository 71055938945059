import React from 'react';
import head from 'lodash.head';
import { StaticQuery, graphql } from 'gatsby';
import RequestInvitationForm from '../components/request-invitation-form';

const RequestInvitationPage = () => (
  <StaticQuery
    query={graphql`
      query requestInvitationPage {
        allContentfulRequestInvitationPage {
          edges {
            node {
              title
              buttonText
              navigationBackgroundColor
              titleColor
              labelColor
              nameLabelText
              emailLabelText
              companyLabelText
              findOutLabelText
              description
              termsAndConditionsText
              termsAndConditionsLink
              seo {
                seoTitle
                seoKeywords {
                  seoKeywords
                }
                seoDescription {
                  seoDescription
                }
              }
              footer {
                id
                links {
                  id
                  externalUrl
                  slug
                  displayName
                }
                logo {
                  id
                  file {
                    url
                    fileName
                    contentType
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={({ allContentfulRequestInvitationPage: { edges } }) => {
      const { node } = head(edges);
      return <RequestInvitationForm node={node} />;
    }}
  />
);

export default RequestInvitationPage;
