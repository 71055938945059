import React from 'react';
import PropTypes from 'prop-types';
import { ButtonSubmit, ButtonGroup } from '../button';
import invitationStyles from './request-invitation-form.module.scss';
import Layout from '../../components/layout';
import Section from '../section';
import Footer from '../../components/footer';

const RequestInvitationForm = ({ node }) => (
  <Layout
    navigationBackgroundColor={node.navigationBackgroundColor}
    seoTitle={node.seo.seoTitle}
    seoDescription={node.seo.seoDescription.seoDescription}
    seoKeywords={node.seo.seoDescription.seoDescription}
  >
    <Section type="sidePaddingOnly" backgroundColor="#fed853">
      <div className={invitationStyles.container}>
        <div className={invitationStyles.text}>
          <h1 className={invitationStyles.title} style={{ color: node.titleColor }}>
            {node.title}
          </h1>
          <p className={invitationStyles.description} style={{ color: node.titleColor }}>
            {node.description}
          </p>
        </div>

        <form
          name="request-invitation"
          action="/success"
          method="POST"
          netlify-honeypot="bot-field"
          netlify
        >
          <input type="hidden" name="form-name" value="request-invitation" />
          <label
            htmlFor="name"
            className={invitationStyles.label}
            style={{ color: node.labelColor }}
          >
            {node.nameLabelText}
            <input type="text" id="name" name="name" className={invitationStyles.input} required />
          </label>

          <label
            htmlFor="company-name"
            className={invitationStyles.label}
            style={{ color: node.labelColor }}
          >
            {node.companyLabelText}
            <input
              type="company-name"
              id="company-name"
              name="company-name"
              className={invitationStyles.input}
            />
          </label>

          <label
            htmlFor="email"
            className={invitationStyles.label}
            style={{ color: node.labelColor }}
          >
            {node.emailLabelText}
            <input
              type="email"
              id="email"
              name="email"
              className={invitationStyles.input}
              required
            />
          </label>

          <label
            htmlFor="find-out"
            className={invitationStyles.label}
            style={{ color: node.labelColor }}
          >
            {node.findOutLabelText}
            <textarea name="message" id="find-out" className={invitationStyles.textarea} />
          </label>

          <label htmlFor="terms-and-conditions" className={invitationStyles.termsAndConditions}>
            <input
              type="checkbox"
              id="terms-and-conditions"
              name="terms-and-conditions"
              className={invitationStyles.checkbox}
              required
            />
            {node.termsAndConditionsText}{' '}
            <a href={node.termsAndConditionsLink} target="_blank" rel="noopener noreferrer">
              Terms and Conditions
            </a>
          </label>
          <ButtonGroup>
            <ButtonSubmit type="primary" backgroundColor="#7a3df5" color="#fff">
              {node.buttonText}
            </ButtonSubmit>
          </ButtonGroup>
        </form>
      </div>
    </Section>
    <Footer node={node.footer} />
  </Layout>
);

RequestInvitationForm.propTypes = {
  node: PropTypes.shape({
    seo: PropTypes.shape({
      seoTitle: PropTypes.string.isRequired,
      seoKeywords: PropTypes.shape({
        seoKeywords: PropTypes.string.isRequired,
      }),
      seoDescription: PropTypes.shape({
        seoDescription: PropTypes.string.isRequired,
      }),
    }),
    footer: PropTypes.shape({
      id: PropTypes.string.isRequired,
      links: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          externalUrl: PropTypes.string.isRequired,
          slug: PropTypes.string.isRequired,
          displayName: PropTypes.string.isRequired,
        })
      ),
      logo: PropTypes.shape({
        id: PropTypes.string.isRequired,
        file: PropTypes.shape({
          url: PropTypes.string.isRequired,
          fileName: PropTypes.string.isRequired,
          contentType: PropTypes.string.isRequired,
        }),
      }),
    }),
    title: PropTypes.string,
    buttonText: PropTypes.string.isRequired,
    navigationBackgroundColor: PropTypes.string.isRequired,
    titleColor: PropTypes.string.isRequired,
    labelColor: PropTypes.string.isRequired,
    nameLabelText: PropTypes.string.isRequired,
    emailLabelText: PropTypes.string.isRequired,
    companyLabelText: PropTypes.string.isRequired,
    findOutLabelText: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
};

export default RequestInvitationForm;
